@import 'colors';

.content-title {
  grid-area: animationTitle;
  background-color: $base-dark-2;
  opacity: 1;
}

@keyframes barAnimation {
  50% {
    height: 20%;
  }
}

.loading {
  grid-area: animation;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-content: center;
  background-color: $base-dark-2;
  opacity: 1;
  padding-top: 100px;

  .bar {
    height: 10%;
    width: 5px;
    background-color: $base-light-2;
    border-radius: 25px;
    margin: 5px;
    animation-name: barAnimation;
    animation-duration: 2s;
    animation-iteration-count: infinite;
  }

  .bar:nth-child(2n) {
    animation-delay: 0.25s;
  }
}

.animations {
  grid-area: content;
  width: 100%;
  height: 100%;
  z-index: 3;
  background-color: $base-dark-2;
  border-top: 5px solid $base-light-2;
  display: grid;
  grid-template-rows: 15vh 5vh 10vh 50vh 20vh;
  grid-template-columns: 7.5% 85% 7.5%;
  grid-template-areas:
    "animationLeft animationTitle animationRight"
    "animationLeft animationDescription animationRight"
    "animationLeft animationChoices animationRight"
    "animationLeft animation animationRight"
    "animationLeft animationBottom animationRight";
  overflow: hidden;
  z-index: 10;
}

.animation-left,
.animation-right,
.animation-bottom {
  grid-area: animationLeft;
  background-color: $base-dark-2;
  opacity: 1;
}

.animation-right {
  grid-area: animationRight;
}

.animation-bottom {
  grid-area: animationBottom;
}

.description {
  grid-area: animationDescription;
  color: $base-light-1;
  background-color: $base-dark-2;
  opacity: 1;
}

.choices {
  grid-area: animationChoices;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  background-color: $base-dark-2;
  opacity: 1;
}

.choice {
  font-size: 25px;
  color: $base-light-1;
  cursor: pointer;
}

.active {
  text-decoration: underline;
  text-decoration-color: $base-light-2;
}

.animation {
  grid-area: animation;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-content: center;
  border: 5px solid $base-light-2;
}

@media screen and (orientation: landscape) and (max-width: 700px) {
  .animations {
    grid-template-rows: 20vh 20vh 60vh;
    grid-template-columns: 5% 45% 5% 45%;
    grid-template-areas:
      "animationLeft animationTitle spacer animation"
      "animationLeft animationDescription spacer animation"
      "animationLeft animationChoices spacer animation"
  }

  .loading {
    grid-area: animationChoices;
  }
}

@media screen and (max-width: 500px) {
  .animations {
    grid-template-rows: 7.5vh 10vh 7.5vh 65vh 10vh;
  }
}
