@import 'colors';
.sim-racing {
    grid-area: content;
    width: 100%;
    height: 100%;
    z-index: 3;
    background-color: $base-dark-1;
    border-top: 5px solid $base-light-2;
    z-index: 10;
    display: grid;
    grid-template-rows: 1fr 700px 3fr;
    grid-template-columns: 100%;
    grid-template-areas: "screenshotsOne" "information" "screenshotsTwo";
    @keyframes raceNumberAnimation {
        0% {
            transform: translateX(-100vw);
        }
        33% {
            transform: translateX(0);
        }
        50% {
            transform: skewX(45deg);
        }
        100% {
            transform: translateX(100vw) skewX(45deg);
        }
    }
    .bold {
        font-weight: bold;
    }
    .loading-animation {
        grid-row-start: 1;
        grid-row-end: 4;
        grid-column-start: 1;
        grid-column-end: 1;
        display: flex;
        width: 100%;
        flex-direction: column;
        justify-content: flex-start;
        background-color: $base-dark-1;
        z-index: 20;
    }
    .loading-item {
        animation: raceNumberAnimation;
        animation-duration: 3s;
        animation-iteration-count: infinite;
        font-family: 'Tangerine';
        color: $base-light-2;
        font-size: 256px;
        height: calc(100vh - 75px);
    }
    @keyframes racingImagesEntrance {
        0% {
            opacity: 0;
        }
        100% {
            opacity: 1;
        }
    }
    .racing-images,
    .racing-images-two {
        grid-area: screenshotsOne;
        max-width: 100vw;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        animation: racingImagesEntrance;
        animation-duration: 3.15s;
        animation-delay: 2.85s;
        .racing-image {
            width: 100%;
            border-bottom: 5px solid $base-light-2;
        }
    }
    .racing-images-two {
        grid-area: screenshotsTwo;
        align-items: center;
        justify-self: center;
        justify-content: space-around;
        width: 100%;
        .racing-image {
            border: 0;
            max-width: 80vw;
        }
    }
    .information {
        color: $base-light-2;
        grid-area: information;
        display: flex;
        flex-direction: column;
        animation: racingImagesEntrance;
        animation-duration: 3.15s;
        animation-delay: 2.85s;
        .person {
            font-size: 48px;
            display: flex;
            flex-direction: row;
            justify-content: space-around;
            align-items: center;
            width: 50%;
            align-self: center;
            padding: 12px;
            .number {
                font-family: 'Tangerine';
                font-size: 96px;
            }
        }
        .ratings {
            width: 100%;
            height: 75%;
            display: flex;
            flex-direction: row;
            justify-content: space-around;
            align-items: center;
            align-self: center;
            color: $base-light-1;
            .results {
                flex-grow: 2;
                height: 100%;
                display: flex;
                flex-direction: column;
                font-size: 12.5px;
                margin-left: 2%;
                .header,
                .stats-item {
                    border-bottom: 1.75px solid $base-light-1;
                    min-height: 50px;
                    width: 100%;
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    flex-grow: 1;
                    .header-item {
                        flex-grow: 1;
                        width: 75px;
                    }
                }
                .stats-item {
                    background-color: $base-light-1;
                    color: $base-dark-1;
                    .stats-item-cell {
                        flex-grow: 1;
                        width: 75px;
                    }
                }
            }
            .licenses {
                border: 1.75px solid $base-light-2;
                flex-grow: 1;
                height: 66.66%;
                display: flex;
                flex-direction: column;
                justify-content: space-around;
                align-items: center;
                align-self: center;
                margin-left: 2%;
                .license {
                    background-color: $base-light-1;
                    width: 85%;
                    height: 30%;
                    color: $base-dark-1;
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    justify-content: space-around;
                    .type {
                        flex-grow: 1;
                    }
                    .points {
                        display: flex;
                        flex-direction: column;
                        align-items: flex-start;
                        flex-grow: 2;
                        height: 80%;
                        justify-content: space-around;
                        padding-left: 40px;
                        border-left: 1.75px solid $base-dark-1;
                    }
                }
            }
        }
    }
    #racing-images-stack-one {
        align-self: flex-start;
    }
    #racing-images-stack-two {
        align-self: flex-end;
    }
    #racing-images-stack-three {
        align-self: flex-start;
    }
}

@media screen and (max-width: 1500px) {
    .sim-racing {
        grid-template-rows: 1fr 1000px 3fr;
        .information {
            .ratings {
                flex-direction: column;
                .licenses {
                    margin-left: unset;
                    width: 33%;
                }
                .results {
                    margin-left: unset;
                    font-size: 9px;
                    .header {
                        .header-item {
                            width: 55px;
                            border-right: 1px solid $base-light-1;
                            word-wrap: break-word;
                        }
                    }
                    .stats-item {
                        .stats-item-cell {
                            width: 55px;
                        }
                    }
                }
            }
        }
    }
}