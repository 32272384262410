@import 'colors';

.footer {
    margin-top: 40px;
    width: 100;
    height: 10px;
    overflow-x: hidden;
    display: flex;
    flex-direction: column;
    align-items: center;

    .stars,
    .starsTwo,
    .starsThree,
    .starsFour,
    .starsFive,
    .starsSix {
        display: flex;
        width: 98vw;
        justify-content: space-between;
        z-index: 0;
        position: absolute;
        :nth-child(3n) {
            animation-name: twinkle;
            animation-duration: 25s;
            animation-timing-function: ease-out;
            animation-iteration-count: infinite;
        }
        :nth-child(6n) {
            animation-name: twinkle;
            animation-duration: 12s;
            animation-timing-function: ease-in;
            animation-iteration-count: infinite;
        }
        :nth-child(9n) {
            animation-name: twinkle;
            animation-duration: 2s;
            animation-timing-function: ease-in-out;
            animation-iteration-count: infinite;
        }
        :last-child,
        :nth-last-child(2),
        :nth-last-child(3),
        :nth-last-child(4) {
            transform: translateX(-10px) !important;
        }
    }

    .stars {
        transform: translateY(-25px);
        :nth-child(2n) {
            transform: rotate(180deg) translateX(20px) translateY(10px);
        }
        :nth-child(3n) {
            animation-name: twinkle;
            animation-duration: 15s;
            animation-timing-function: ease-in;
            animation-iteration-count: infinite;
            transform: rotate(180deg) translateX(10px) translateY(5px);
        }
        :nth-child(4n) {
            transform: rotate(180deg) translateX(-20px) translateY(15px);
        }
        :nth-child(8n) {
            animation-name: twinkle;
            animation-duration: 15s;
            animation-timing-function: ease-in;
            animation-iteration-count: infinite;
        }
        :nth-child(11n) {
            animation-name: twinkle;
            animation-duration: 10s;
            animation-timing-function: ease-in-out;
            animation-iteration-count: infinite;
        }
    }

    .starsTwo {
        transform: translateY(-30px);
        :nth-child(2n) {
            transform: rotate(180deg) translateX(30px) translateY(10px);
        }
        :nth-child(3n) {
            transform: rotate(270deg) translateX(10px) translateY(5px);
        }
        :nth-child(4n) {
            transform: rotate(270deg) translateX(-30px) translateY(15px);
        }
    }

    .starsThree {
        transform: translateY(-65px);
        :nth-child(2n) {
            transform: rotate(270deg) translateX(10px) translateY(15px);
        }
        :nth-child(3n) {
            animation-name: twinkle;
            animation-duration: 5s;
            animation-timing-function: ease-out;
            animation-iteration-count: infinite;
            transform: rotate(270deg) translateX(15px) translateY(5px);
        }
        :nth-child(4n) {
            transform: rotate(270deg) translateX(-20px) translateY(25px);
        }
        :nth-child(6n) {
            animation-name: twinkle;
            animation-duration: 7.5s;
            animation-timing-function: ease-in;
            animation-iteration-count: infinite;
        }
        :nth-child(9n) {
            animation-name: twinkle;
            animation-duration: 2s;
            animation-timing-function: ease-in-out;
            animation-iteration-count: infinite;
        }
    }

    .starsFour {
        transform:  translateY(-115px);
        :nth-child(2n) {
            transform: rotate(180deg) translateX(10px) translateY(15px);
        }
        :nth-child(3n) {
            transform: rotate(180deg) translateX(15px) translateY(5px);
        }
        :nth-child(4n) {
            transform: rotate(180deg) translateX(-20px) translateY(25px);
        }
    }

    .starsFive {
        transform: translateY(-95px);
        :nth-child(2n) {
            transform: rotate(180deg) translateX(10px) translateY(15px);
        }
        :nth-child(3n) {
            transform: rotate(180deg) translateX(15px) translateY(5px);
        }
        :nth-child(4n) {
            transform: rotate(180deg) translateX(-20px) translateY(25px);
        }
    }

    .starsSix {
        transform:  translateY(-170px);
        :nth-child(2n) {
            transform: rotate(180deg) translateX(10px) translateY(15px);
        }
        :nth-child(3n) {
            transform: rotate(180deg) translateX(15px) translateY(5px);
        }
        :nth-child(4n) {
            transform: rotate(180deg) translateX(-20px) translateY(25px);
        }
    }
}