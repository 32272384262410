@import '../colors';

.animation-two {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-content: space-around;
  flex-wrap: wrap;
  overflow: hidden;
  height: 100%;
  background-color: $base-light-2;
}

@keyframes secondAnimationFirstChildrenAnimation {
  25% {
  }
  50% {
    transform: scale3d(-1.25, 1, 1) translate3d(75px, 25px, 0px);
  }
  75% {
  }
}

.animation-two-object {
  width: 200px;
  height: 50px;
  background: $base-dark-1;
  position: relative;
  animation-name: secondAnimationFirstChildrenAnimation;
  animation-duration: 20s;
  animation-iteration-count: infinite;
  animation-timing-function: ease-in-out;
  animation-delay: 2.5s;
  margin-right: 20px;

  &:after {
    content: '';
    position: absolute;
    left: 0;
    bottom: 0;
    width: 0;
    height: 0;
    border-left: 25px solid $base-light-2;
    border-top: 25px solid transparent;
    border-bottom: 25px solid transparent;
  }

  &:before {
    content: '';
    position: absolute;
    right: -25px;
    bottom: 0;
    width: 0;
    height: 0;
    border-left: 25px solid $base-dark-1;
    border-top: 25px solid transparent;
    border-bottom: 25px solid transparent;
  }
}

@keyframes secondAnimationSecondChildrenAnimation {
  25% {
    transform: translate3d(0px, -25px, 0px);
  }
  50% {
    transform: scale3d(1.5, 1, 1);
  }
  75% {
  }
}

.animation-two-object:nth-child(2n) {
  background: $base-dark-2;
  animation-name: secondAnimationSecondChildrenAnimation;
  animation-duration: 20s;
  animation-iteration-count: infinite;
  animation-delay: 2.5s;
  z-index: 1;

  &:after {
  }

  &:before {
    border-left-color: $base-dark-2;
  }
}

.animation-two-object:nth-child(6n) {
  background: $base-light-1;

  &:before {
    border-left-color: $base-light-1;
  }
}

@keyframes secondAnimationTenthChildrenAnimation {
  25% {
    transform: translate3d(0px, -25px, 0px);
  }
  50% {
    transform: scale3d(1.5, 1, 1);
    background-color: $base-light-1;
  }
  75% {
  }
}

.animation-two-object:nth-child(10n) {
  background-color: $base-dark-3;
  animation-name: secondAnimationTenthChildrenAnimation;

  &:after {
    border-left-color: $base-dark-3;
  }

  &:before {
    border-left-color: $base-dark-3;
  }
}
