@import 'colors';

.wedding {
    display: grid;
    grid-template-rows: 550px 1fr;
    grid-template-columns: 100vw;
    grid-template-areas:
      "header"
      "content";
    z-index: 0;
    width: 100vw;
    height: fit-content;
    color: #edddaa;
    background-color: #040a1a;

    .wedding-content {
        grid-area: content;
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-top: 40px;

        
        img.cute-couple,
        img.cute-couple-two {
            border: 1px solid #edddaa;
            padding: 10px;
            z-index: 10;
        }

        img.cute-couple-two {
            margin-bottom: 75px;
        }

        .date-and-time,
        .venue {
            margin: 100px 0px 50px 0px;
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-content: center;
            font-family: 'Libre Baskerville';
            background-color: #edddaa;
            color: #040a1a;
            height: 500px;
            width: 100%;
        }

        .venue {
            background-color: #040a1a;
            color: #edddaa;
        }

        .border-box,
        .border-box-alternate {
            align-self: center;
            height: 95%;
            width: 100%;
            margin: 10px;
            border: 1px solid #040a1a;
            display: flex;
            flex-direction: column;
            justify-content: space-evenly;
            align-content: center;

            .date {
                font-size: 32px;
                text-decoration: underline;
            }

            .time {
                font-size: 22px;
                font-style: italic;
            }
        }

        .text-color-link,
        .text-color-link-opposite {
            color: #edddaa;
            text-decoration: underline;
            cursor: pointer;
        }


        .text-color-link-opposite {
            color: #040a1a;
        }

        .border-box-alternate {
            border: 1px solid #edddaa;
        }
    }
}

@media screen and (max-width: 500px) {
    .wedding {
        .wedding-content {
            .date-and-time,
            .venue {
                margin-top: 50px;
                height: 700px;
            }

            .border-box,
            .border-box-alternate {
                padding: 10px;
                .time {
                    font-size: 16px;
                }
            }
        }
    }
}