@import 'colors';

.content-title {
  font-size: 30px;
  color: $base-light-1;
  text-decoration-line: underline;
  text-decoration-color: $base-light-2;
  text-decoration-style: solid;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
