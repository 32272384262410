@import 'colors';
.resume {
    grid-area: content;
    display: grid;
    grid-template-rows: 0.5fr 1fr 0.45fr 0.10fr;
    grid-template-columns: 1fr 66.66% 1fr;
    grid-template-areas: "profile profile profile" "left-spacer employment right-spacer" "left-spacer education right-spacer" "left-spacer projects right-spacer";
    width: 100%;
    z-index: 3;
    background-color: $base-light-1;
}

.profile {
    grid-area: profile;
    background-color: $base-dark-2;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    padding: 25px;
}

.profile-info {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    padding: 50px;
    flex-grow: 3;
}

.profile-statement {
    font-size: 15px;
    flex-grow: 2;
    max-width: 50%;
    color: $base-light-1;
}

.profile-image {
    border-radius: 200px;
    flex-grow: 1;
}

.headshot-image {
    max-height: 400px;
    max-width: 400px;
    border-radius: 200px;
}

.employment {
    grid-area: employment;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-top: 40px;
}

.education {
    grid-area: education;
    padding-top: 40px;
}

.bottom-animation {
    margin: 40px 0px;
    height: 100px;
}

@keyframes triangle1Color {
    25% {
        border-top: 50px solid $base-dark-2;
        border-right: 50px solid transparent;
        border-bottom: 50px solid transparent;
    }
    50% {
        border-top: 50px solid $base-dark-1;
        border-right: 50px solid transparent;
        border-bottom: 50px solid transparent;
    }
    75% {
        border-top: 50px solid $base-dark-3;
        border-right: 50px solid transparent;
        border-bottom: 50px solid transparent;
    }
}

@keyframes triangle2Color {
    25% {
        border-top: 50px solid transparent;
        border-right: 50px solid transparent;
        border-bottom: 50px solid $base-dark-1;
    }
    50% {
        border-top: 50px solid transparent;
        border-right: 50px solid transparent;
        border-bottom: 50px solid $base-dark-3;
    }
    75% {
        border-top: 50px solid transparent;
        border-right: 50px solid transparent;
        border-bottom: 50px solid $base-light-2;
    }
}

@keyframes triangle3Color {
    25% {
        border-top: 50px solid transparent;
        border-left: 50px solid transparent;
        border-bottom: 50px solid $base-dark-3;
    }
    50% {
        border-top: 50px solid transparent;
        border-left: 50px solid transparent;
        border-bottom: 50px solid $base-light-2;
    }
    75% {
        border-top: 50px solid transparent;
        border-left: 50px solid transparent;
        border-bottom: 50px solid $base-dark-2;
    }
}

@keyframes triangle4Color {
    25% {
        border-top: 50px solid $base-light-2;
        border-left: 50px solid transparent;
        border-bottom: 50px solid transparent;
    }
    50% {
        border-top: 50px solid $base-dark-2;
        border-left: 50px solid transparent;
        border-bottom: 50px solid transparent;
    }
    75% {
        border-top: 50px solid $base-dark-1;
        border-left: 50px solid transparent;
        border-bottom: 50px solid transparent;
    }
}

.triangle-1,
.triangle-5,
.triangle-9 {
    position: absolute;
    left: 33.33%;
    width: 0;
    height: 0;
    border-top: 50px solid $base-light-2;
    border-right: 50px solid transparent;
    border-bottom: 50px solid transparent;
    animation-name: triangle1Color;
    animation-duration: 5s;
    animation-timing-function: linear;
    animation-iteration-count: infinite;
}

.triangle-2,
.triangle-6,
.triangle-10 {
    position: absolute;
    left: 33.33%;
    width: 0;
    height: 0;
    border-top: 50px solid transparent;
    border-right: 50px solid transparent;
    border-bottom: 50px solid $base-dark-2;
    animation-name: triangle2Color;
    animation-duration: 5s;
    animation-timing-function: linear;
    animation-iteration-count: infinite;
}

.triangle-3,
.triangle-7,
.triangle-11 {
    position: absolute;
    right: 33.33%;
    width: 0;
    height: 0;
    border-top: 50px solid transparent;
    border-left: 50px solid transparent;
    border-bottom: 50px solid $base-dark-1;
    animation-name: triangle3Color;
    animation-duration: 5s;
    animation-timing-function: linear;
    animation-iteration-count: infinite;
}

.triangle-4,
.triangle-8,
.triangle-12 {
    position: absolute;
    right: 33.33%;
    width: 0;
    height: 0;
    border-top: 50px solid $base-dark-3;
    border-left: 50px solid transparent;
    border-bottom: 50px solid transparent;
    animation-name: triangle4Color;
    animation-duration: 5s;
    animation-timing-function: linear;
    animation-iteration-count: infinite;
}

.triangle-5 {
    left: calc(50%);
}

.triangle-6 {
    left: calc(50%);
}

.triangle-7 {
    left: calc(50% - 50px);
}

.triangle-8 {
    left: calc(50% - 50px);
}

.triangle-9 {
    left: calc(66.66%);
}

.triangle-10 {
    left: calc(66.66%);
}

.triangle-11 {
    left: calc(33.33% - 50px);
}

.triangle-12 {
    left: calc(33.33% - 50px);
}

.projects {
    grid-area: projects;
}

.section {
    font-size: 25px;
    font-style: italic;
    text-align: left;
}

.section-title {
    color: $base-dark-2;
    text-decoration-line: underline;
    text-decoration-color: $base-light-2;
    text-decoration-style: solid;
}

.company {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    padding: 25px;
    font-size: 15px;
}

.company-info {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-content: flex-start;
    text-align: left;
    flex-grow: 1;
    min-width: 400px;
    max-width: 400px;
}

.company-info-item {
    margin-right: 20px;
    font-size: 20px;
}

.link {
    color: $base-dark-2;
    text-decoration: none;
}

.company-name {
    margin-bottom: 15px;
    color: $base-dark-2;
}

.company-title {
    margin-bottom: 15px;
    color: $base-dark-2;
}

.company-product {
    font-style: italic;
    margin-bottom: 15px;
    color: $base-dark-2;
}

.company-description {
    margin-bottom: 15px;
}

.company-technical {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    text-align: left;
    flex-grow: 2;
    min-height: 200px;
    font-style: normal;
}

@media screen and (max-width: 1224px) {
    .resume {
        grid-template-rows: 0.5fr 1fr 0.20fr 0.10fr;
    }
    .profile-info {
        flex-direction: column;
        justify-content: space-between;
    }
    .profile-statement {
        margin-bottom: 50px;
    }
    .company {
        flex-direction: column;
    }
    .company-info {
        margin-bottom: 20px;
    }
    .company-technical {
        min-height: 75px;
    }
}

@media screen and (max-width: 500px) {
    .resume {
        grid-template-columns: 1fr 98vw 1fr;
    }
    .profile-info {
        padding: 10px;
    }
    .profile-statement {
        max-width: 90%;
    }
    .headshot-image {
        height: 200px;
        width: 200px;
    }
    .section {
        max-width: 100%;
    }
    .company {
        padding: 0px;
        margin-top: 20px;
    }
    .company-info {
        max-width: 94vw;
        min-width: 90vw;
    }
    .company-info-item {
        margin-right: unset;
    }
}