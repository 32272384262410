@import 'colors';
@keyframes shiftRightColorBar9 {
    25% {
        transform: rotate(40deg) translateY(-200px);
        background-color: $base-dark-3;
        animation-timing-function: linear;
    }
    50% {
        transform: rotate(40deg) translateY(-260px);
        background-color: $base-dark-2;
        animation-timing-function: ease-in;
    }
    75% {
        transform: rotate(40deg) translateY(-200px);
        background-color: $base-dark-3;
        animation-timing-function: linear;
    }
    100% {
        transform: rotate(40deg) translateY(-140px);
        background-color: $base-light-2;
        animation-timing-function: ease-out;
    }
}

@keyframes shiftRightColorBar10 {
    25% {
        transform: rotate(40deg) translateY(-220px);
        background-color: $base-dark-2;
        animation-timing-function: linear;
    }
    50% {
        transform: rotate(40deg) translateY(-280px);
        background-color: $base-light-2;
        animation-timing-function: ease-in;
    }
    75% {
        transform: rotate(40deg) translateY(-220px);
        background-color: $base-dark-2;
        animation-timing-function: linear;
    }
    100% {
        transform: rotate(40deg) translateY(-160px);
        background-color: $base-dark-3;
        animation-timing-function: ease-out;
    }
}

@keyframes shiftRightColorBar11 {
    25% {
        transform: rotate(40deg) translateY(-240px);
        background-color: $base-light-2;
        animation-timing-function: linear;
    }
    50% {
        transform: rotate(40deg) translateY(-300px);
        background-color: $base-dark-3;
        animation-timing-function: ease-in;
    }
    75% {
        transform: rotate(40deg) translateY(-240px);
        background-color: $base-light-2;
        animation-timing-function: linear;
    }
    100% {
        transform: rotate(40deg) translateY(-180px);
        background-color: $base-dark-2;
        animation-timing-function: ease-out;
    }
}

.header-container {
    box-sizing: border-box;
    overflow: hidden;

    header {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
        height: 75px;
        padding-left: 50px;
        background-color: $base-dark-1;
        color: $base-light-1;
        z-index: 1;
        grid-area: header;
    }
}

.item,
.item-primary {
    font-size: 20px;
    margin-right: 25px;
    text-decoration: none;
    color: $base-light-1;
}

.item-primary {
    font-size: 25px;
}

.color-bar-1,
.color-bar-2,
.color-bar-3,
.color-bar-4,
.color-bar-5,
.color-bar-6,
.color-bar-7,
.color-bar-8,
.color-bar-9,
.color-bar-10,
.color-bar-11,
{
    position: fixed;
    height: 100px;
    width: 100vw;
    background-color: $base-dark-2;
    transform: rotate(320deg);
    z-index: 2;
    transform-origin: center;
}

.color-bar-2,
.color-bar-6 {
    height: 100px;
    background-color: $base-dark-3;
    transform: rotate(325deg) translateX(125px);
    // transform: rotate(325deg);
}

.color-bar-6 {
    transform: rotate(345deg) translatex(225px);
    // transform: rotate(345deg);
}

.color-bar-3,
.color-bar-5 {
    background-color: $base-light-2;
    transform: rotate(330deg) translateX(150px);
}

.color-bar-5 {
    transform: rotate(340deg) translatex(200px);
}

.color-bar-4 {
    background-color: $base-light-1;
    transform: rotate(335deg) translateX(175px);
}

.color-bar-7 {
    transform: rotate(350deg) translatex(250px);
}

.color-bar-8 {
    height: 100px;
    background-color: $base-light-1;
    transform: rotate(355deg) translatex(275px);
}

.color-bar-9 {
    height: 30px;
    background-color: $base-light-2;
    transform: rotate(40deg) translateY(-140px);
    animation-name: shiftRightColorBar9;
    animation-duration: 40s;
    animation-timing-function: ease-in;
    animation-iteration-count: infinite;
}

.color-bar-10 {
    height: 30px;
    background-color: $base-dark-3;
    transform: rotate(40deg) translateY(-160px);
    animation-name: shiftRightColorBar10;
    animation-duration: 40s;
    animation-timing-function: ease-in;
    animation-iteration-count: infinite;
    animation-delay: 1s;
}

.color-bar-11 {
    height: 30px;
    background-color: $base-dark-2;
    transform: rotate(40deg) translateY(-180px);
    animation-name: shiftRightColorBar11;
    animation-duration: 40s;
    animation-timing-function: ease-in;
    animation-iteration-count: infinite;
    animation-delay: 2s;
}

.separator {
    height: 30px;
    width: 2px;
    margin-right: 25px;
    background-color: $base-light-2;
    border-radius: 2px;
}

@media screen and (max-width: 1500px) {
    .color-bar-1,
    .color-bar-2,
    .color-bar-3,
    .color-bar-4,
    .color-bar-5,
    .color-bar-6,
    .color-bar-7,
    .color-bar-8,
    .color-bar-9,
    .color-bar-10,
    .color-bar-11,
    {
        display: none;
    }
}

@media screen and (max-width: 500px) {
    header {
        padding-left: 5px;
    }
    .item-primary {
        font-size: 22.5px;
    }
    .color-bar-1,
    .color-bar-2,
    .color-bar-3,
    .color-bar-4,
    .color-bar-5,
    .color-bar-6,
    .color-bar-7,
    .color-bar-8,
    .color-bar-9,
    .color-bar-10,
    .color-bar-11,
    {
        display: none;
    }
}