@import 'colors';
@import url('https://fonts.googleapis.com/css2?family=Tangerine:wght@700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Libre+Baskerville:wght@700&display=swap');

body {
  overflow-x: hidden;
  width: 100vw;
  background-color: #040a1a;
}

.App {
  text-align: center;
  background-color: $base-light-1;
  color: $base-dark-1;
  display: grid;
  grid-template-rows: 75px 1fr;
  grid-template-columns: 100vw;
  grid-template-areas:
    "header"
    "content";
  z-index: 0;
  width: 100vw;
  height: 100vh;
}
