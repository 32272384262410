$base-tan-light: #F3E4C6;
$base-brown: #8F4F06;
$base-white: #E6EFF3;
$base-blue-light: #73C0F4;
$base-blue-dark: #728CA3;

$base-dark-1: #10000C;
$base-dark-2: #515B51;
$base-dark-3: #8C7547;
$base-light-1: #DAC6AC;
$base-light-2: #EFA747;
