@import '../colors';

@keyframes moveRight {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(100%);
  }
}

@keyframes moveLeft {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(-100%);
  }
}

@keyframes moveUp {
  0% {
    transform: translateY(0);
  }
  100% {
    transform: translateY(-400%);
  }
}

@keyframes moveDown {
  0% {
    transform: translateY(0);
  }
  100% {
    transform: translateY(400%);
  }
}

@keyframes revealRight {
  0% {
    width: 0;
  }
  100% {
    width: 100%;
  }
}

@keyframes revealTextDark {
  100% {
    color: $base-dark-1;
  }
}

@keyframes revealTextLight {
  100% {
    color: $base-light-1;
  }
}

.animation-three {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-content: center;
  height: 100%;
  border: 2px solid $base-dark-1;
  padding: 25px;
  overflow: hidden;

  > div {
    color: transparent;
    font-size: 60px;
    height: 100px;
    line-height: 100px;
  }

  .first {
    background: $base-dark-1;
    position: relative;
    overflow: hidden;
    animation: revealTextLight 0s 3s forwards, moveUp 3s 6s forwards;
    display: inline;

    &:after {
      content: '';
      width: 0%;
      height: 100px;
      position: absolute;
      top: 0;
      left: 0;
      display: block;
      background: $base-dark-2;
      animation: revealRight 3s forwards, moveRight 3s 3s forwards;
    }
  }

  .last {
    background: $base-light-1;
    position: relative;
    overflow: hidden;
    animation: revealTextDark 0s 3s forwards, moveDown 3s 6s forwards;
    display: inline;

    &:before {
      content: '';
      width: 0%;
      height: 100px;
      position: absolute;
      top: 0;
      right: 0;
      display: block;
      background: $base-dark-2;
      animation: revealRight 3s forwards, moveLeft 3s 3s forwards;
    }
  }
}
