@import 'colors';

@keyframes twinkle {
    0% {
        opacity: 0;
    }
    25% {
        opacity: 0.3;
    }
    50% {
        opacity: 0.6;
    }
    75% {
        opacity: 0.4;
    }
    100% {
        opacity: 0;
    }
}

.wedding-header-container {
    box-sizing: border-box;
    overflow: hidden;
    grid-area: header;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    .stars,
    .starsTwo,
    .starsThree,
    .starsFour,
    .starsFive,
    .starsSix {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        min-height: 75px;
        :nth-child(3n) {
            animation-name: twinkle;
            animation-duration: 25s;
            animation-timing-function: ease-out;
            animation-iteration-count: infinite;
        }
        :nth-child(6n) {
            animation-name: twinkle;
            animation-duration: 12s;
            animation-timing-function: ease-in;
            animation-iteration-count: infinite;
        }
        :nth-child(9n) {
            animation-name: twinkle;
            animation-duration: 6s;
            animation-timing-function: ease-in-out;
            animation-iteration-count: infinite;
        }
        :last-child,
        :nth-last-child(2),
        :nth-last-child(3),
        :nth-last-child(4) {
            transform: translateX(-10px) !important;
        }
    }

    .starsTwo {
        transform: translateY(140%);
        min-height: 25px;
        width: 100%;
        position: absolute;
        :nth-child(2n) {
            animation-name: twinkle;
            animation-duration: 15s;
            animation-timing-function: ease-in;
            animation-iteration-count: infinite;
            transform: rotate(180deg) translateY(30px) translateX(-50px);
        }
        :nth-child(4n) {
            transform: rotate(90deg) translateY(60px) translateX(-25px);
        }
        :nth-child(5n) {
            transform: rotate(90deg) translateY(30px) translateX(-15px);
        }
        :nth-child(7n) {
            animation-name: twinkle;
            animation-duration: 15s;
            animation-timing-function: ease-in;
            animation-iteration-count: infinite;
        }
        :nth-child(10n) {
            animation-name: twinkle;
            animation-duration: 10s;
            animation-timing-function: ease-in-out;
            animation-iteration-count: infinite;
        }
    }

    .starsThree {
        transform: translateY(70%);
        align-self: center;
        justify-content: space-around;
        width: 100%;
        min-height: 25px;
        position: absolute;
        :nth-child(2n) {
            transform: rotate(180deg) translateY(20px) translateX(-15px);
        }
        :nth-child(3n) {
            transform: rotate(30deg) translateY(45px) translateX(-25px);
        }
        :nth-child(4n) {
            animation-name: twinkle;
            animation-duration: 5s;
            animation-timing-function: ease-out;
            animation-iteration-count: infinite;
            transform: rotate(30deg) translateY(35px) translateX(-35px);
        }
        :nth-child(5n) {
            animation-name: twinkle;
            animation-duration: 7.5s;
            animation-timing-function: ease-in;
            animation-iteration-count: infinite;
        }
        :nth-child(8n) {
            animation-name: twinkle;
            animation-duration: 2s;
            animation-timing-function: ease-in-out;
            animation-iteration-count: infinite;
        }
    }

    .starsFour {
        transform: translateY(115%);
        width: 100%;
        position: absolute;
        :nth-child(2n) {
            transform: rotate(180deg) translateY(20px) translateX(-15px);
        }
        :nth-child(3n) {
            transform: rotate(30deg) translateY(45px) translateX(-25px);
        }
        :nth-child(4n) {
            transform: rotate(30deg) translateY(35px) translateX(-35px);
        }
    }

    .starsFive {
        transform: translateY(145%);
        width: 100%;
        position: absolute;
        :nth-child(2n) {
            transform: rotate(180deg) translateY(30px) translateX(-50px);
        }
        :nth-child(4n) {
            transform: rotate(90deg) translateY(60px) translateX(-25px);
        }
        :nth-child(5n) {
            transform: rotate(90deg) translateY(30px) translateX(-15px);
        }
    }

    .starsSix {
        transform: translateY(205%);
        width: 100%;
        position: absolute;
        :nth-child(2n) {
            transform: rotate(180deg) translateY(50px) translateX(-30px);
        }
        :nth-child(3n) {
            transform: rotate(30deg) translateY(45px) translateX(-25px);
        }
        :nth-child(4n) {
            transform: rotate(90deg) translateY(25px) translateX(-5px);
        }
        :nth-child(5n) {
            transform: rotate(30deg) translateY(15px) translateX(-5px);
        }
    }

    .names {
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        font-family: 'Libre Baskerville';

        .name {
            font-size: 56px;
            display: flex;
            flex-direction: column;
            align-content: center;
            justify-content: center;
            min-height: 60px;
        }
    }

    .links {
        display: flex;
        flex-direction: row;
        align-self: center;
        justify-content: space-evenly;
        min-width: 50%;
        margin-top: 20px;

        .wedding-link {
            font-size: 24px;
            text-decoration: underline;
            color: #edddaa;
            margin: 5px;
        }
    }
}

@media screen and (max-width: 500px) {
    .wedding-header-container {
        .names {
            margin-top: 55px;
            
            .name {
                font-size: 40px;
            }
        }

        .links {
            flex-direction: column;
        }
    }
}