@import '../colors';

@keyframes drawArc1 {
  0% {
    transform: rotate(360deg);
  }
  100% {
    transform: rotate(0deg);
  }
}

@keyframes drawArc2 {
  0% {
    transform: rotate(360deg) scale(0.1) translateY(1250px);
  }
  100% {
    transform: rotate(0deg) scale(0.1) translateY(1300px);
  }
}

@keyframes drawArc3 {
  0% {
    transform: rotate(360deg) scale(0.2) translateY(1000px);
  }
  100% {
    transform: rotate(0deg) scale(0.2) translateY(1050px);
  }
}

@keyframes drawArc4 {
  0% {
    transform: rotate(360deg) scale(0.25) translateY(1200px);
  }
  100% {
    transform: rotate(0deg) scale(0.25) translateY(1250px);
  }
}

@keyframes drawArc5 {
  0% {
    transform: rotate(360deg) scale(0.23) translateY(1700px);
  }
  100% {
    transform: rotate(0deg) scale(0.23) translateY(1750px);
  }
}

@keyframes drawArc6 {
  0% {
    transform: rotate(360deg) scale(0.75) translateY(850px);
  }
  100% {
    transform: rotate(0deg) scale(0.75) translateY(900px);
  }
}

@keyframes drawArc7 {
  0% {
    transform: rotate(360deg) scale(0.65) translateY(1250px);
  }
  100% {
    transform: rotate(0deg) scale(0.65) translateY(1300px);
  }
}

@keyframes drawArc8 {
  0% {
    transform: rotate(360deg) scale(0.45) translateY(2100px);
  }
  100% {
    transform: rotate(0deg) scale(0.45) translateY(2150px);
  }
}

@keyframes drawArc9 {
  0% {
    transform: rotate(360deg) scale(0.4) translateY(2700px);
  }
  100% {
    transform: rotate(0deg) scale(0.4) translateY(2750px);
  }
}

@keyframes drawArc10 {
  0% {
    transform: rotate(360deg) scale(0.03) translateY(9000%);
  }
  100% {
    transform: rotate(0deg) scale(0.03) translateY(9005%);
  }
}

@keyframes drawArc11 {
  0% {
    transform: rotate(360deg) scale(0.03) translateY(20000%);
  }
  33% {
    transform: rotate(0deg) scale(0.03) translateY(1000%);
  }
  66% {
    transform: rotate(0deg) scale(0.03) translateY(20000%);
  }
  100% {
    transform: rotate(0deg) scale(0.03) translateY(1000%);
  }
}

.animation-four {
  height: 100%;
  width: 100%;
  z-index: -1;
  background-color: $base-dark-1;

  .bubble {
    height: 200px;
    width: 200px;
    box-shadow: 0 20px 30px rgba(0, 0, 0, 0.2), inset 0px 10px 30px 5px $base-light-2;
    position: relative;
    border-radius: 50%;
    top: 25%;
    left: -100px;
    animation: drawArc1 10s linear infinite;

    &:after {
      background: radial-gradient(ellipse at center, rgba(255, 255, 255, 0.5) 0%, rgba(255, 255, 255, 0) 70%);
      border-radius: 50%;
      box-shadow: inset 0 20px 30px rgba(255, 255, 255, 0.3);
      content: "";
      height: 180px;
      left: 10px;
      position: absolute;
      width: 180px;
    }
  }

  .x2 {
    animation: drawArc2 10s linear infinite;
    top: calc(25% - 200px);
    box-shadow: 0 20px 30px rgba(0, 0, 0, 0.2), inset 0px 10px 30px 5px $base-dark-1;
  }

  .x3 {
    animation: drawArc3 11s linear infinite;
    top: calc(25% - 400px);
  }

  .x4 {
    animation: drawArc4 13s linear infinite;
    top: calc(25% - 600px);
    box-shadow: 0 20px 30px rgba(0, 0, 0, 0.2), inset 0px 10px 30px 5px $base-dark-2;
  }

  .x5 {
    animation: drawArc5 15s linear infinite;
    top: calc(25% - 800px);
    box-shadow: 0 20px 30px rgba(0, 0, 0, 0.2), inset 0px 10px 30px 5px $base-dark-3;
  }

  .asteroid {
    animation: drawArc10 13s linear infinite;
  }

  .a1 {
    top: calc(25% - 1800px);
  }

  .a2 {
    top: calc(25% - 1985px);
    animation-duration: 13.25s;
    animation-delay: 0.25s;
    box-shadow: 0 20px 30px rgba(0, 0, 0, 0.2), inset 0px 10px 30px 5px $base-dark-3;
  }

  .a3 {
    top: calc(25% - 2190px);
    animation-duration: 13.3s;
    animation-delay: 0.40s;
    box-shadow: 0 20px 30px rgba(0, 0, 0, 0.2), inset 0px 10px 30px 5px $base-dark-2;
  }

  .a4 {
    top: calc(25% - 2395px);
    animation-duration: 13.15s;
    animation-delay: 0.55s;
    box-shadow: 0 20px 30px rgba(0, 0, 0, 0.2), inset 0px 10px 30px 5px $base-dark-1;
  }

  .a5 {
    top: calc(25% - 2590px);
    animation-duration: 13.2s;
    animation-delay: 0.6s;
    box-shadow: 0 20px 30px rgba(0, 0, 0, 0.2), inset 0px 10px 30px 5px $base-light-1;
  }

  .a6 {
    top: calc(25% - 2790px);
    animation-duration: 13.05s;
    animation-delay: 0.75s;
  }

  .a7 {
    top: calc(25% - 2995px);
    animation-duration: 13.07s;
    animation-delay: 0.85s;
    box-shadow: 0 20px 30px rgba(0, 0, 0, 0.2), inset 0px 10px 30px 5px $base-dark-1;
  }

  .a8 {
    top: calc(25% - 3192px);
    animation-duration: 13.17s;
    animation-delay: 0.935s;
    box-shadow: 0 20px 30px rgba(0, 0, 0, 0.2), inset 0px 10px 30px 5px $base-dark-2;
  }

  .a9 {
    top: calc(25% - 3397px);
    animation-duration: 13.27s;
    animation-delay: 1.035s;
    box-shadow: 0 20px 30px rgba(0, 0, 0, 0.2), inset 0px 10px 30px 5px $base-light-1;
  }

  .a10 {
    top: calc(25% - 3585px);
    animation-duration: 13.37s;
    animation-delay: 1.235s;
  }

  .a11 {
    top: calc(25% - 3790px);
    animation-duration: 13.02s;
    animation-delay: 1.345s;
    box-shadow: 0 20px 30px rgba(0, 0, 0, 0.2), inset 0px 10px 30px 5px $base-dark-3;
  }

  .a12 {
    top: calc(25% - 3995px);
    animation-duration: 13.22s;
    animation-delay: 1.545s;
    box-shadow: 0 20px 30px rgba(0, 0, 0, 0.2), inset 0px 10px 30px 5px $base-dark-1;
  }

  .a13 {
    top: calc(25% - 4190px);
    animation-duration: 13.32s;
    animation-delay: 1.6s;
  }

  .a14 {
    top: calc(25% - 4375px);
    animation-duration: 13s;
    animation-delay: 1.75s;
  }

  .a15 {
    top: calc(25% - 4595px);
    animation-duration: 13.3s;
    animation-delay: 1.85s;
  }

  .comet {
    animation: drawArc11 30s linear infinite;
    top: calc(25% - 4795px);
    box-shadow: 0 20px 30px rgba(0, 0, 0, 0.2), inset 0px 10px 30px 5px $base-light-1;

    &:after {
      background: radial-gradient(ellipse at center, rgba(255, 255, 255, 0.5) 0%, rgba(255, 255, 255, 0) 70%);
      border-radius: 50%;
      box-shadow: inset 0 20px 30px rgba(255, 255, 255, 0.3);
      content: "";
      height: 1300px;
      left: 10px;
      position: absolute;
      width: 180px;
    }

    &:before {
      background: radial-gradient(ellipse at center, rgba(255, 255, 255, 0.5) 0%, rgba(255, 255, 255, 0) 80%);
      border-radius: 50%;
      box-shadow: inset 0 20px 30px rgba(255, 255, 255, 0.4);
      content: "";
      height: 1700px;
      left: 10px;
      position: absolute;
      width: 180px;
    }
  }

  .x6 {
    animation: drawArc6 40s linear infinite;
    top: calc(25% - 1000px);
  }

  .x7 {
    animation: drawArc7 45s linear infinite;
    top: calc(25% - 1200px);
    box-shadow: 0 20px 30px rgba(0, 0, 0, 0.2), inset 0px 10px 30px 5px $base-light-1;
  }

  .x8 {
    animation: drawArc8 57s linear infinite;
    top: calc(25% - 1400px);
    box-shadow: 0 20px 30px rgba(0, 0, 0, 0.2), inset 0px 10px 30px 5px $base-dark-1;
  }

  .x9 {
    animation: drawArc9 63s linear infinite;
    top: calc(25% - 1600px);
    box-shadow: 0 20px 30px rgba(0, 0, 0, 0.2), inset 0px 10px 30px 15px $base-dark-1;
  }
}
