@import '../colors';

.animation-one {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-content: space-around;
  flex-wrap: wrap;
  overflow: hidden;
  height: 100%;
}

@keyframes firstAnimationFirstChildrenAnimation {
  25% {
    transform: rotate(90deg);
  }
  50% {
    transform: rotate(135deg);
  }
  75% {
    transform: rotate(270deg);
  }
}

.animation-one-object {
  width: 100px;
  height: 100px;
  border: 5px solid $base-dark-1;
  border-radius: 0 100%;
  background-color: $base-light-2;
  animation-name: firstAnimationFirstChildrenAnimation;
  animation-duration: 20s;
  animation-iteration-count: infinite;
  animation-delay: 2.5s;
}

@keyframes firstAnimationSecondChildrenAnimation {
  25% {
    transform: rotate(270deg);
    background-color: $base-dark-2;
    border-color: $base-light-2;
  }
  50% {
    transform: rotate(135deg);
    background-color: $base-light-1;
    border-color: $base-dark-1;
  }
  75% {
    transform: rotate(45deg);
    background-color: $base-dark-2;
    border-color: $base-light-2;
  }
}

.animation-one-object:nth-child(2n) {
  border-radius: 100% 0%;
  background-color: $base-light-1;
  animation-name: firstAnimationSecondChildrenAnimation;
}

@keyframes firstAnimationFourthChildrenAnimation {
  25% {
    transform: rotate(135deg);
    background-color: $base-dark-1;
  }
  50% {
    transform: rotate(45deg);
    background-color: $base-light-1;
  }
  75% {
    transform: rotate(270deg);
    background-color: $base-dark-1;
  }
}

.animation-one-object:nth-child(4n) {
  animation-name: firstAnimationFourthChildrenAnimation;
  animation-direction: alternate;
  border-color: $base-dark-1;
}
